import { HTTP_METHODS } from "src/constants";
import { makeCall } from "src/helpers";
import { isRLTLang } from "src/i18n";
import { store } from "src/configureStore";
import { isEmpty } from "lodash";
const FETCH_ENDPOINT = "/api/country_list";
const LOCAL_STORAGE_KEY = "foreignNationalitiesList";

const formatResponse = (items, param) =>
  items.map((item) => ({
    label: isRLTLang() ? item.labelNationalityAr : item.labelNationalityFr,
    value: item.idCountry,
    ...item,
  })).filter(item => item.idCountry !== 'CE')

const formatResponseForeignNationality = (items, param) =>{
  items = items.filter(item => item.labelCountryFr.toLowerCase() !== "tunisie");  
  return items.map((item) => ({
    label: isRLTLang() ? item.labelNationalityAr : item.labelNationalityFr,
    value: item.idCountry,
    ...item,
  })).filter(item => item.idCountry !== 'CE')}

export const NationalitiesUIHelper = (callback) => {
  const { token } = store.getState().common.auth || {}
  console.log("NationalitiesUIHelper",token)

  return new Promise((resolve, reject) =>
    makeCall(HTTP_METHODS.GET, FETCH_ENDPOINT, { _pagination: false }, { Authorization: `Bearer ${token}` })
      .then((resp) => resolve(callback(formatResponse(resp.data))))
      .catch(() => reject(callback([])))
  );

};

export const ForeignNationalitiesUIHelper = (callback,recaptchaToken) => {
  const cachedData = localStorage.getItem(LOCAL_STORAGE_KEY);
  if (cachedData) {
    // If data is available in localStorage, return it
    callback(JSON.parse(cachedData));
    return Promise.resolve();
  }
  const { token } = store.getState().common.auth || null
  let headers = {};
  if(token){
    headers = { Authorization: `Bearer ${token}` } 
    console.log("ForeignNationalitiesUIHelper token ",token)

  }else{
    headers["Captcha-Token"] = recaptchaToken; // Add token to headers 
    console.log("ForeignNationalitiesUIHelper recaptchaToken ",recaptchaToken)

  }
  return new Promise((resolve, reject) =>
    makeCall(HTTP_METHODS.GET, FETCH_ENDPOINT, { _pagination: false }, headers)
      .then((resp) => {
        const formattedData = formatResponseForeignNationality(resp.data);
        // Save formatted data to localStorage
        localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(formattedData));
        resolve(callback(formattedData));
      })
      .catch(() => reject(callback([])))
  );

};

const formatValueOptions = (options) => {
  let values = {}
  options.forEach((option) => {
    values[option.idCountry] = isRLTLang() ? option.labelNationalityAr : option.labelNationalityFr
  })
  return values;
}
export const NationalitiesOptionsUIHelper = (callback) => {
  const { token } = store.getState().common.auth || null
  console.log("NationalitiesOptionsUIHelper",token)

  return new Promise((resolve, reject) =>
    makeCall(HTTP_METHODS.GET, FETCH_ENDPOINT, { _pagination: false }, { Authorization: `Bearer ${token}` })
      .then((resp) => resolve(callback(formatValueOptions(resp.data))))
      .catch(() => reject(callback([])))
  );

};
