import { ACTIONS } from "./../constants"
import { PURGE } from "redux-persist"


const initialState = { 
  currentUser: null, 
  evaxNumber: null,
  cin:null,
  phoneNumber: null,
  permissions: null,
  token: null,
  captchaRef: null,
  isAuthenticated: false,
  isSuperuser: false,
  isLoading: false, 
  isFetching: false, 
  success: false, 
  evaxNumberRecup : false,
  changeOtpSuccess: false,
  successFlash:false,
  error: null,
  sucessPhone: false,
  errorPhone: null,
  otpError: null,
  errorRecup : null,
  resetRecapcha:false,
  isLoading_recup_code:false
}


export default (state = initialState, action) => {
  const { payload, type } = action
  
  switch (type) {

    case ACTIONS.CLEAR_ERROR : {
      return { ...state, isLoading: undefined, isFetching: false, success: false, error: null,otpError: null ,errorPhone: null, errorRecup : null,evaxNumberRecup : false,successFlash:false}
    }
    case ACTIONS.CLEAR_SUCCESS : {
      return { ...state, success: false,successFlash:false,changeOtpSuccess:false,evaxNumberRecup: false }
    }
    case ACTIONS.LOGIN_INIT : {
      return { ...state, isLoading: true, error: null }
    }
    case ACTIONS.LOGIN_SUCCEDED : {
      return { ...state, isLoading: false, evaxNumber:  payload?.num_evax ,cin:  payload?.cin,phoneNumber:payload?.phone,  error: null, success:true }
    }
    case ACTIONS.SHOW_ERROR : {
        return { ...state, isLoading: false, error: payload }
      }
    case ACTIONS.SHOW_SUCCESS:{
      return {
        ...state,
        successFlash:true,
        error:null
      };
    }

    case ACTIONS.PROFILE_UPDATED_INIT : {
      return { ...state, isLoading: true,error: null }
    }
    case ACTIONS.PROFILE_UPDATED_SUCCEDED : {
      console.log(state.currentUser , payload)
      return { ...state, isLoading: false, error: null , currentUser : {profile : payload} ,phoneNumber:payload.phone, success: true  }
    }
    case ACTIONS.PROFILE_UPDATED_SUCCEDED_NO_SUCCESS_MSG : {
      return { ...state, isLoading: false, error: null  ,phoneNumber:payload.phone  }
    }
    case ACTIONS.PROFILE_UPDATED_FAILED : {
      return { ...state, isLoading: false, error: payload }
    }




    case ACTIONS.LOGIN_FAILED : {
      return { ...state, isLoading: false, error: payload, success : false }
    } 

    case ACTIONS.OTP_INIT : {
      return { ...state, isLoading: true, otpError: null }
    }
    case ACTIONS.OTP_SUCCEDED: {
      const { profile, access } = payload
      const token = access
      return { ...state, isLoading: false, isAuthenticated: true, currentUser: { profile }, token, otpError: null }
    }
    case ACTIONS.OTP_FAILED : {
      return { ...state, isLoading: false, otpError: payload }
    }

    case ACTIONS.LOGOUT_INIT : {
      return { ...state, isLoading: true, error: null }
    }
    case ACTIONS.LOGOUT_SUCCEDED : {
      return { ...state, ...initialState }
    }
    case PURGE : {
      return { ...state, ...initialState }
    }
    case ACTIONS.LOGOUT_FAILED : {
      return { ...state, isLoading: false, error: payload }
    }

    case ACTIONS.UPDATE_PERSONAL_INFORMATION_SUCCEDED : {
      return { ...state, currentUser: { user: payload } }
    }

    case ACTIONS.FORGOT_PASSWORD_INIT : {
      return { ...state, isLoading: true, error: null, success: false }
    }
    case ACTIONS.FORGOT_PASSWORD_SUCCEDED : {
      return { ...state, isLoading: false, success: true, error: null }
    }
    case ACTIONS.FORGOT_PASSWORD_FAILED : {
      return { ...state, isLoading: false, error: payload }
    }

    case ACTIONS.INSCRIPTION_OTP_INIT : {
      return { ...state, isLoading: true, error: null, success: false,evaxNumber:null }
    }
    case ACTIONS.INSCRIPTION_OTP_SUCCEDED : {
      return { ...state, isLoading: false, error: null,evaxNumber:payload.numEvax }
    }
    case ACTIONS.INSCRIPTION_OTP_FAILED : {
      return { ...state, isLoading: false, error: payload }
    }


    case ACTIONS.CHANGE_PHONE_OTP_INIT : {
      return { ...state, isLoading: true, errorPhone: null, success: false,evaxNumber:null }
    }
    case ACTIONS.CHANGE_PHONE_OTP_SUCCEDED : {
      return { ...state, isLoading: false, errorPhone: null,changeOtpSuccess:true }
    }
    case ACTIONS.CHANGE_PHONE_OTP_FAILED : {
      return { ...state, isLoading: false, errorPhone: payload}
    }

    case ACTIONS.EVAX_NUMBER_RECUP_INIT : {
      return { ...state, isLoading_recup_code: true, errorPhone: null, success: false,evaxNumber:null }
    }
    case ACTIONS.EVAX_NUMBER_RECUP_SUCCEDED : {
      return { ...state, isLoading_recup_code: false, errorPhone: null,evaxNumberRecup:true }
    }
    case ACTIONS.EVAX_NUMBER_RECUP_FAILED : {
      return { ...state, isLoading_recup_code: false, errorRecup: payload}
    }


    case ACTIONS.RESET_PASSWORD_INIT : {
      return { ...state, isLoading: true, error: null, success: false }
    }
    case ACTIONS.RESET_PASSWORD_SUCCEDED : {
      const { user, token } = payload
      const permissions = (user.roles||[]).reduce((acc, role) => acc.concat(role.permissions), user.permissions || [])
      return { ...state, isLoading: false, isAuthenticated: true, currentUser: { user }, isSuperuser: user.isSuperAdmin , permissions, token, error: null }
    }
    case ACTIONS.RESET_PASSWORD_FAILED : {
      return { ...state, isLoading: false, error: payload }
    }
    case ACTIONS.CAPTCHA_REF:{
      return {...state,captchaRef:payload}
    } 
    case ACTIONS.CHANGE_PHONE_LOGGED_CITIZEN_INIT : {
      return { ...state, isLoading: true, error: null, success: false }
    }
    case ACTIONS.CHANGE_PHONE_LOGGED_CITIZEN_SUCCEDED : {
      return { ...state, isLoading: false, error: null }
    }
    case ACTIONS.CHANGE_PHONE_LOGGED_CITIZEN_FAILED : {
      return { ...state, isLoading: false, error: payload }
    }
    case ACTIONS.CHANGE_PHONE_LOGGED_CITIZEN_OTP_INIT : {
      return { ...state, isLoading: true, error: null, success: false }
    }
    case ACTIONS.CHANGE_PHONE_LOGGED_CITIZEN_OTP_SUCCEDED : {
      return { ...state, isLoading: false , error: null,changeOtpSuccess:true }
    }
    case ACTIONS.CHANGE_PHONE_LOGGED_CITIZEN_OTP_FAILED : {
      return { ...state, isLoading: false, error: payload }
    }
    case ACTIONS.RESET_RECAPCHA : {
      return { ...state, resetRecapcha:true}
    }
    case ACTIONS.CLEAR_RESET_RECAPCHA : {
      return { ...state, resetRecapcha:false}
    }
    default: {
      return state
    }
  }
}
