export const CLEAR_ERROR = "common@CLEAR_ERROR"
export const CLEAR_SUCCESS = "common@CLEAR_SUCCESS"


export const SHOW_SUCCESS = "common@SHOW_SUCCESS"
export const SHOW_ERROR = "common@SHOW_ERROR"


export const LOGIN_INIT = "authentication@LOGIN_INIT"
export const LOGIN_SUCCEDED =  "authentication@LOGIN_SUCCEDED"
export const LOGIN_FAILED = "authentication@LOGIN_FAILED"

export const OTP_INIT = "authentication@OTP_INIT"
export const OTP_SUCCEDED =  "authentication@OTP_SUCCEDED"
export const OTP_FAILED = "authentication@OTP_FAILED"

export const REGISTER_INIT = "authentication@REGISTER_INIT"
export const REGISTER_SUCCEDED = "authentication@REGISTER_SUCCEDED"
export const REGISTER_FAILED = "authentication@REGISTER_FAILED"

export const FORGOT_PASSWORD_INIT = "authentication@FORGOT_PASSWORD_INIT"
export const FORGOT_PASSWORD_SUCCEDED = "authentication@FORGOT_PASSWORD_SUCCEDED"
export const FORGOT_PASSWORD_FAILED = "authentication@FORGOT_PASSWORD_FAILED"

export const FETCH_TOKEN_INIT = "authentication@FETCH_TOKEN_INIT"
export const FETCH_TOKEN_SUCCEDED = "authentication@FETCH_TOKEN_SUCCEDED"
export const FETCH_TOKEN_FAILED = "authentication@FETCH_TOKEN_FAILED"

export const UPDATE_PERSONAL_INFORMATION_SUCCEDED = "profile@UPDATE_PERSONAL_INFORMATION_SUCCEDED"

export const RESET_PASSWORD_INIT = "authentication@RESET_PASSWORD_INIT"
export const RESET_PASSWORD_SUCCEDED = "authentication@RESET_PASSWORD_SUCCEDED"
export const RESET_PASSWORD_FAILED = "authentication@RESET_PASSWORD_FAILED"

export const LOGOUT_INIT =  "authentication@LOGOUT_INIT"
export const LOGOUT_SUCCEDED = "authentication@LOGOUT_SUCCEDED"
export const LOGOUT_FAILED = "authentication@LOGOUT_FAILED"


export const INSCRIPTION_OTP_INIT =  "INSCRIPTION_OTP@INSCRIPTION_OTP_INIT"
export const INSCRIPTION_OTP_SUCCEDED = "INSCRIPTION_OTP@INSCRIPTION_OTP_SUCCEDED"
export const INSCRIPTION_OTP_FAILED = "INSCRIPTION_OTP@INSCRIPTION_OTP_FAILED"

export const INSCRIPTION_BIO_OTP_INIT =  "INSCRIPTION_BIO_OTP@INSCRIPTION_OTP_INIT"
export const INSCRIPTION_BIO_OTP_SUCCEDED = "INSCRIPTION_BIO_OTP@INSCRIPTION_OTP_SUCCEDED"
export const INSCRIPTION_BIO_OTP_FAILED = "INSCRIPTION_BIO_OTP@INSCRIPTION_OTP_FAILED"


export const CHANGE_PHONE_OTP_INIT =  "CHANGE_PHONE_OTP@CHANGE_PHONE_OTP_INIT"
export const CHANGE_PHONE_OTP_SUCCEDED = "CHANGE_PHONE_OTP@CHANGE_PHONE_OTP_SUCCEDED"
export const CHANGE_PHONE_OTP_FAILED = "CHANGE_PHONE_OTP@CHANGE_PHONE_OTP_FAILED"

export const EVAX_NUMBER_RECUP_INIT =  "EVAX_NUMBER_RECUP@EVAX_NUMBER_RECUP_INIT"
export const EVAX_NUMBER_RECUP_SUCCEDED = "EVAX_NUMBER_RECUP@EVAX_NUMBER_RECUP_SUCCEDED"
export const EVAX_NUMBER_RECUP_FAILED = "EVAX_NUMBER_RECUP@EVAX_NUMBER_RECUP_FAILED"


export const CAPTCHA_REF = "CAPTCHA_REF@CAPTCHA_REF"


export const PROFILE_UPDATED_INIT = "structure@PROFILE_UPDATED_INIT"
export const PROFILE_UPDATED_SUCCEDED = "structure@PROFILE_UPDATED_SUCCEDED"
export const PROFILE_UPDATED_FAILED = "structure@PROFILE_UPDATED_FAILED"
export const PROFILE_UPDATED_SUCCEDED_NO_SUCCESS_MSG = "structure@PROFILE_UPDATED_SUCCEDED_NO_SUCCESS_MSG"

export const CHANGE_PHONE_LOGGED_CITIZEN_INIT = "structure@CHANGE_PHONE__LOGGED_CITIZEN_INIT"
export const CHANGE_PHONE_LOGGED_CITIZEN_SUCCEDED = "structure@CHANGE_PHONE__LOGGED_CITIZEN_SUCCEDED"
export const CHANGE_PHONE_LOGGED_CITIZEN_FAILED = "structure@CHANGE_PHONE__LOGGED_CITIZEN_FAILED"

export const CHANGE_PHONE_LOGGED_CITIZEN_OTP_INIT = "structure@CHANGE_PHONE__LOGGED_CITIZEN_OTP_INIT"
export const CHANGE_PHONE_LOGGED_CITIZEN_OTP_SUCCEDED = "structure@CHANGE_PHONE__LOGGED_CITIZEN_OTP_SUCCEDED"
export const CHANGE_PHONE_LOGGED_CITIZEN_OTP_FAILED = "structure@CHANGE_PHONE__LOGGED_CITIZEN_OTP_FAILED"

export const CLEAR_RESET_RECAPCHA = "captcha@CLEAR_RESET_RECAPCHA"
export const RESET_RECAPCHA = "captcha@RESET_RECAPCHA"
